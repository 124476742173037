import { RiCheckboxCircleLine } from '@remixicon/react';
import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { DropdownSelect } from 'shared/ui';

const EditExchangeConfigInfoUi = () => {
	const [form] = Form.useForm();

	const onFinish = (v) => console.log(v);

	return (
		<Form layout="vertical" form={form} onFinish={onFinish}>
			<Typography.Title level={1} style={{ marginBottom: 12 }}>
				Настройки конфигурации обмена
			</Typography.Title>
			<Form.Item label="Наименование конфигурации" name="name_configuration" required>
				<Input />
			</Form.Item>
			<Form.Item label="Описание" name="description">
				<Input />
			</Form.Item>
			<Form.Item
				label="Действие при удалении позиции во внешней системе"
				name="on_delete_action"
			>
				<DropdownSelect />
			</Form.Item>
			<Form.Item label="Статус" name="status">
				<DropdownSelect />
			</Form.Item>
			<Form.Item label="Режим импорта позиций" name="">
				<DropdownSelect />
			</Form.Item>
			<Form.Item label="Регулярное выражение для настройки расписания обмена" name="">
				<Input placeholder="Введите CRON-выражение" />
			</Form.Item>
			<Typography.Title level={2} style={{ marginBottom: 12 }}>
				Настройки маппинга
			</Typography.Title>
			<Form.Item label="Справочник, в который будут импортированы позиции" name="">
				<DropdownSelect />
			</Form.Item>
			<Form.Item label="Источник данных" name="" required>
				<Input />
			</Form.Item>
			<Button icon={<RiCheckboxCircleLine />} style={{ width: '100%' }} htmlType="submit">
				Подтвердить выбранный источник данных
			</Button>
		</Form>
	);
};

export const EditExchangeConfigInfo = React.memo(EditExchangeConfigInfoUi);
