import { RiEditLine } from '@remixicon/react';
import { Table, Flex } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { DeleteExchangeConfig } from 'features/integrations/ExchangeConfiguration/deleteExchangeConfig';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { Chip, DetailFooter } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';

const data = Array.from({ length: 120 }, (_, index) => ({
	key: index,
	id: randomString(10),
	name: 'ИС_Бухгалтерия: Импорт контрагентов',
	linkedReference: `Справочник_Данные ${index + 1}`,
	replicationDirection: index % 2 === 0 ? 'Импорт' : 'Экспорт',
	status: index % 3 === 0 ? 'success' : 'error',
}));

const ExchangeConfigWidgetUi: React.FC = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [pageSize, setPageSize] = useState(20);

	const onSelectChange = (selectedKeys) => {
		setSelectedRowKeys(selectedKeys);
	};

	const navigate = useNavigate();

	const handleNavigate = useCallback((id: string) => navigate(id), []);
	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	const columns = [
		{
			title: 'Наименование конфигурации',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Связанные справочники',
			dataIndex: 'linkedReference',
			key: 'linkedReference',
			filters: [
				{ text: 'Справочник 1', value: 'Справочник 1' },
				{ text: 'Справочник 2', value: 'Справочник 2' },
				{ text: 'Справочник 3', value: 'Справочник 3' },
			],
			onFilter: (value, record) => record.linkedReference.includes(value),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Направление репликации',
			dataIndex: 'replicationDirection',
			key: 'replicationDirection',
			sorter: (a, b) => a.replicationDirection.localeCompare(b.replicationDirection),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{ text: 'Выполняется', value: 'Выполняется' },
				{ text: 'Остановлена', value: 'Остановлена' },
			],
			onFilter: (value, record) => record.status.includes(value),
			render: (status) => (
				<Chip status={status}>{status === 'error' ? 'Остановлена' : 'Выполняется'}</Chip>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_, record) => (
				<AppDropdown
					items={[
						{
							label: (
								<Flex gap={4}>
									<RiEditLine size={16} onClick={() => navigate(record.id)} />
									Редактировать
								</Flex>
							),
							key: '1',
						},
						{
							label: <DeleteExchangeConfig id={[record.id]} />,
							key: '2',
						},
					]}
				/>
			),
		},
	];

	return (
		<>
			<Table
				rowSelection={{
					selectedRowKeys,
					onChange: onSelectChange,
				}}
				onRow={(row) => ({
					onClick: () => handleNavigate(row.id),
				})}
				columns={columns}
				dataSource={data}
				pagination={{
					pageSize,
					total: data.length,
					showTotal: (total) => `Всего ${total}`,
					showSizeChanger: true,
					onShowSizeChange: (current, size) => setPageSize(size),
				}}
			/>
			<DetailFooter customHandleBack={customHandleBack}>
				<DeleteExchangeConfig id={selectedRowKeys} disabled={!selectedRowKeys.length} />
			</DetailFooter>
		</>
	);
};

export const ExchangeConfigWidget = React.memo(ExchangeConfigWidgetUi);
