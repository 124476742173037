import { RiArrowRightSLine } from '@remixicon/react';
import { Breadcrumb, Flex, Layout } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Outlet, Link } from 'react-router-dom';
import UserProfile from 'widgets/UserProfile';
import { IBreadcrumb } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppSelector } from 'shared/hooks';
import { LayoutHeader } from 'shared/ui';
import { Sidebar, Logo, Menu } from 'shared/ui';
import s from './pageLayout.module.scss';

const { Content } = Layout;

const PageLayout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState<boolean>(true);
	const [breadcrumbs, setBreadcrumbs] = useState<Array<{ title: React.ReactElement }> | null>(
		null
	);

	const { root } = useAppSelector((state) => state.breadcrumbs);

	const { hasSider: sider, subMenuCollapsed } = useAppSelector((state) => state.shared.ui);

	useEffect(() => {
		if (root) {
			const getBreadcrumbs = (root: IBreadcrumb) => {
				const resultArray: { title: JSX.Element }[] = [];
				resultArray.push({
					title: root.child ? (
						<Link to={root.url}>{root.displayName}</Link>
					) : (
						<span>{root.displayName}</span>
					),
				});
				if (root.child) {
					resultArray.push(...getBreadcrumbs(root.child));
				}
				return resultArray;
			};

			setBreadcrumbs(getBreadcrumbs(root));
		} else {
			setBreadcrumbs(null);
		}
	}, [root]);

	const toggleMenu = () => setCollapsed(!collapsed);

	useEffect(() => {
		if (collapsed && subMenuCollapsed)
			document.documentElement.style.setProperty('--aside-width', '55px');

		if (collapsed && !subMenuCollapsed)
			document.documentElement.style.setProperty('--aside-width', '410px');

		if (!collapsed && subMenuCollapsed) {
			document.documentElement.style.setProperty('--aside-width', '250px');
		}

		if (!collapsed && !subMenuCollapsed && sider)
			document.documentElement.style.setProperty('--aside-width', '590px');
	}, [collapsed, subMenuCollapsed]);

	useEffect(() => {
		if (location.pathname === '/') navigate(`/${routes.metadata.main}`);
	}, [location]);

	const shouldShowBreadcrumbs =
		location.pathname.split('/').length > 3 ||
		(location.pathname.includes(routes.metadata.measures) &&
			location.pathname.split('/').length > 3) ||
		(location.pathname.includes(routes.classifiers.main) &&
			location.pathname.split('/').length > 2) ||
		(location.pathname.includes(routes.integrations.main) &&
			location.pathname.split('/').length > 2) ||
		(location.pathname.includes(routes.metadata.constraintTables) &&
			location.pathname.split('/').length > 3);

	return (
		<Layout className={s.mainLayout}>
			<LayoutHeader>
				<Flex align="center" gap={41}>
					<Menu onClick={toggleMenu} />
					<Logo />
				</Flex>
				<UserProfile />
			</LayoutHeader>
			<Layout hasSider>
				<Sidebar collapsed={collapsed} />
				<Content className={s.content}>
					{shouldShowBreadcrumbs && (
						<div
							className={cn([
								s.breadCrumbs,
								!subMenuCollapsed && sider && s.slideLeft,
							])}
						>
							<Breadcrumb
								items={breadcrumbs}
								separator={<RiArrowRightSLine size={16} />}
							/>
						</div>
					)}
					<div className={cn([sider && !subMenuCollapsed && s.slideLeft])}>
						<Outlet />
					</div>
				</Content>
			</Layout>
		</Layout>
	);
};

export default PageLayout;
