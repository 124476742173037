import { Flex, Result, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CatalogGroupTreeWidget } from 'widgets/Catalogs/CatalogGroupTreeWidget';
import { Transactions } from 'widgets/Transactions';
import { AddCatalogRecordUi } from 'features/catalogs/CatalogRecords/AddCatalogRecord';
import { ImportCatalogRecord } from 'features/catalogs/importCatalogRecord';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useCatalogRecords } from 'entities/catalogs/catalogRecords';
import {
	ICatalogRecords,
	useRecordsDtoToTableMapper,
} from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useAppDispatch } from 'shared/hooks';
import { Placeholder, Search, setSubMenuCollapsed, WorkTable } from 'shared/ui';
import { SubSider } from 'shared/ui/components/SubSider';
import { PlaceholderStyle } from './styles';

export const CatalogPage: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { catalogList, loading, selectedCatalog } = useCatalogGroups();

	const {
		catalogRecordsDataSource,
		catalogRecordsColumns,
		catalogRecordError,
		catalogRecordLoading,
		searchHandler,
		searchValue,
	} = useCatalogRecords();

	const { mapRecordsDtoToTable, isItemLoading } = useRecordsDtoToTableMapper();

	const [catalogRecordsTableRows, setCatalogRecordsTableRows] = useState<ICatalogRecords | null>(
		null
	);

	const isCatalogEmpty = !catalogRecordsTableRows || !catalogRecordsTableRows?.length;

	useEffect(() => {
		if (catalogRecordsDataSource && catalogRecordsColumns)
			mapRecordsDtoToTable(catalogRecordsDataSource).then((res) =>
				setCatalogRecordsTableRows(res)
			);
	}, [catalogRecordsDataSource, catalogRecordsColumns]);

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	return (
		<>
			<SubSider>
				<CatalogGroupTreeWidget />
			</SubSider>

			<Flex vertical gap={24}>
				<Flex justify="space-between" align="top">
					<Typography.Title level={1}>
						{selectedCatalog && selectedCatalog.displayName}
					</Typography.Title>

					<Transactions />
				</Flex>

				{selectedCatalog && (
					<>
						<Search onChange={searchHandler} defaultValue={searchValue} />
						<Flex gap={4}>
							<AddCatalogRecordUi />
							<ImportCatalogRecord />
						</Flex>
					</>
				)}

				<Flex justify="center" align="center" style={PlaceholderStyle}>
					{!catalogList?.length && !selectedCatalog && !loading && (
						<Placeholder title="Нет справочных групп" />
					)}

					{catalogList?.length !== 0 && !selectedCatalog && !loading && (
						<Placeholder title="Выберите справочную группу для отображения списка записей" />
					)}
				</Flex>

				{catalogRecordError && (
					<Result
						title="Ошибка при получении записей справочника!"
						subTitle={JSON.stringify(catalogRecordError)}
					/>
				)}

				{selectedCatalog && !catalogRecordError && (
					<>
						{!isCatalogEmpty || catalogRecordLoading || isItemLoading ? (
							<WorkTable
								columns={catalogRecordsColumns}
								dataSource={catalogRecordsTableRows}
								loading={catalogRecordLoading || isItemLoading}
								noFilters={true}
								onRow={(r) => ({
									onClick: () =>
										navigate(`${window.location.pathname}/record/${r.id}`),
								})}
							/>
						) : (
							<Placeholder
								title={
									searchValue
										? 'По вашему запросу ничего не найдено'
										: 'Справочник не содержит позиций'
								}
								subTitle={
									searchValue
										? 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить'
										: 'Для добавления новой позиции нажмите на кнопку «Добавить справочную запись»'
								}
								isSearchIcon={!!searchValue}
							/>
						)}
					</>
				)}
			</Flex>
		</>
	);
};
