import { RiCloseLine } from '@remixicon/react';
import { Button, Drawer, Flex } from 'antd';
import React, { PropsWithChildren, ReactNode, useState } from 'react';
import {
	RightDrawerStyles,
	containerStyle,
	extraStyle,
	ButtonStyle,
	DisabledButtonStyle,
	drawerSize,
} from './style';

interface IRightDrawer {
	children?: ReactNode;
	title?: string;
	openButton?: ReactNode;
	disabled?: boolean;
}

export const RightDrawer: React.FC<PropsWithChildren<IRightDrawer>> = ({
	children,
	title,
	openButton,
	disabled,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onClose = () => {
		setIsOpen(false);
	};

	const openDrawer = () => {
		setIsOpen(true);
	};

	const buttonContent: ReactNode = openButton ? openButton : 'Открыть правую панель';

	return (
		<>
			<Button
				onClick={openDrawer}
				style={disabled ? DisabledButtonStyle : ButtonStyle}
				type="text"
				disabled={disabled}
			>
				{buttonContent}
			</Button>

			<div style={containerStyle}>
				<Drawer
					title={title}
					placement="right"
					closable={false}
					open={isOpen}
					getContainer={false}
					mask={false}
					maskClosable={false}
					styles={RightDrawerStyles}
					size={drawerSize}
					extra={
						<Flex align="center" style={extraStyle}>
							<RiCloseLine size={16} onClick={onClose} />
						</Flex>
					}
				>
					{children}
				</Drawer>
			</div>
		</>
	);
};
