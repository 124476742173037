import { RiEditLine } from '@remixicon/react';
import { Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AddClassifierRecord } from 'features/classifiers/ClassifierRecords/AddClassifierRecord';
import { DeleteClassifierRecord } from 'features/classifiers/ClassifierRecords/DeleteClassifierRecord';
import {
	DisplayTreeNode,
	useClassifierItemsTreeHook,
} from 'entities/classifiers/classifiersItemsTree/classifierItemsTree.model';
import {
	ClassifierWithChildren,
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
} from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import { ClassifierItemDto, ClassifierItemTreeNode } from 'shared/api/generatedApi/newUdmiApi';
import { routes } from 'shared/config';
import { isEmpty, randomString, truncateString } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';

export const useClassifierItemTree = () => {
	const [selectedTreeItem, setSelectedTreeItem] = useState<DisplayTreeNode | null>(null);
	const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

	const { selectedClassifierTreeItem, classifiersTree, getClassifiersTreeItem, error, loading } =
		useClassifierItemsTreeHook();

	const { classifierItemId, classifierGroupId } = useParams();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const handleExpand = (ids: React.Key[]) => {
		setExpandedKeys(ids);
	};

	const formatToTree = (data: Array<ClassifierItemDto | ClassifierItemTreeNode>) =>
		data?.map((classifier) => ({
			...classifier,
			key: classifier.id,
			title: truncateString(classifier?.displayName, 30),
			isLeaf: !classifier.parent,
			...(classifier.parent &&
				'children' in classifier && {
					children: formatToTree(classifier.children),
				}),
		}));

	const findClassifierById = (tree: DisplayTreeNode[], classifierId: string): DisplayTreeNode => {
		for (const item of tree) {
			if (item.id === classifierId) {
				return item;
			}
			if (item.children) {
				const found = findClassifierById(item.children, classifierId);
				if (found) {
					return found;
				}
			}
		}
		return null;
	};

	const selectCurrentClassifier = (classifier: DisplayTreeNode | null) => {
		const searchValue = searchParams.get('searchParentValue');
		if (classifier) {
			navigate(
				`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${classifier.id}?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);

			const foundItem = findClassifierById(formatToTree(classifiersTree), classifier.id);

			setSelectedTreeItem(foundItem);
		} else {
			navigate(
				`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);
			setSelectedTreeItem(null);
		}
	};

	const addMenuToTreeNodeItems = (arr: Array<ClassifierWithChildren>) => {
		return arr.map((item) => {
			return {
				...item,
				key: item?.id,
				title: (
					<Flex justify="space-between" align="center" style={{ width: '100%' }}>
						{item.displayName === 'null' || !item.displayName
							? 'без названия'
							: truncateString(item?.displayName, 30)}

						<AppDropdown
							items={[
								{
									key: randomString(5),
									label: <AddClassifierRecord parentId={item.id} />,
								},
								{
									key: randomString(5),
									label: (
										<DropdownLink
											icon={<RiEditLine size={16} />}
											title="Редактировать"
											callback={() => {
												dispatch(setCurrentClassifierTreeItem(item));
												navigate(
													selectedClassifierTreeItem
														? `/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${item.id}/${routes.classifiers.edit}`
														: `${item.id}/${routes.classifiers.edit}`
												);
											}}
										/>
									),
								},
								{
									key: randomString(4),
									label: <DeleteClassifierRecord recordId={item.id} />,
								},
							]}
						/>
					</Flex>
				),
				...(item.children && { children: addMenuToTreeNodeItems(item.children) }),
			};
		});
	};

	const findExpandedKeys = (treeData, targetKey: string): React.Key[] => {
		let keys: React.Key[] = [];

		const traverse = (nodes, parentKeys = []) => {
			for (const node of nodes) {
				const currentKeys = [...parentKeys, node.key];
				if (node.key === targetKey) {
					keys = currentKeys;
					return true;
				}
				if (node.children && traverse(node.children, currentKeys)) {
					return true;
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const findExpandedSearchKeys = (treeData) => {
		const keys = [];

		const traverse = (nodes) => {
			for (const node of nodes) {
				if (node.children) {
					keys.push(node.key);
					traverse(node.children);
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const onExpandTree = async (treeNode) => {
		if (!isEmpty(treeNode?.children)) return;
		await getClassifiersTreeItem(treeNode?.id);
	};

	useEffect(() => {
		if (classifiersTree.length) {
			const formattedTree = formatToTree(classifiersTree);
			if (searchParams.get('searchParentValue')) {
				setExpandedKeys(findExpandedSearchKeys(formattedTree));
				selectedClassifierTreeItem &&
					setSelectedTreeItem(formatToTree([selectedClassifierTreeItem])[0]);
			} else if (selectedClassifierTreeItem) {
				setSelectedTreeItem(formatToTree([selectedClassifierTreeItem])[0]);
				setExpandedKeys(findExpandedKeys(formattedTree, selectedClassifierTreeItem.id));
			}
		}
	}, [classifiersTree]);

	useEffect(() => {
		if (!classifierItemId) {
			setSelectedTreeItem(null);
		}
	}, [classifierItemId, selectedTreeItem]);

	useEffect(() => {
		return () => {
			dispatch(setClassifiersItemsTree(null));
		};
	}, []);

	return {
		selectedTreeItem,
		selectCurrentClassifier,
		loading,
		error,
		formatToTree,
		addMenuToTreeNodeItems,
		classifiersTree,
		expandedKeys,
		handleExpand,
		onExpandTree,
	};
};
