import { TabsProps, Tabs, Flex, Typography, Result, Skeleton } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ExchangeConfigWidget } from 'widgets/Integrations/ExchangeConfigsList';
import { EditIntegrationMainInfo } from 'features/integrations/editIntegrationMainInfo';
import { DeleteExchangeConfig } from 'features/integrations/ExchangeConfiguration/deleteExchangeConfig';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { useGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';

const IntegrationDetailUi = () => {
	const [activeKey, setActiveKey] = useState<string>('connectionInfo');
	const navigate = useNavigate();
	const { integrationId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const { data, isLoading, error } =
		useGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery({
			connectionId: integrationId,
		});

	const tabs: TabsProps['items'] = useMemo(
		() => [
			{
				key: 'exchangeConfigs',
				label: 'Конфигурации обмена',
				children: <ExchangeConfigWidget />,
			},
			{
				key: 'connectionInfo',
				label: 'Информация о подключении',
				children: (
					<EditIntegrationMainInfo isLoading={isLoading} error={error} data={data} />
				),
			},
		],
		[data]
	);

	const onChange = useCallback((key: string) => {
		navigate(
			`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${key}`
		);
	}, []);

	useEffect(() => {
		setActiveKey(pathname.split('/').pop());
	}, [pathname]);

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Интеграции',
				url: routes.integrations.main,
				child: {
					displayName: 'Карточка подключения',
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
				},
			})
		);
	}, []);

	if (error) <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;

	return (
		<Flex vertical gap={24}>
			{isLoading && <Skeleton active />}
			{!isLoading && !error && (
				<>
					<Typography.Title level={1}>{data?.connection_info?.name}</Typography.Title>
					<Tabs
						items={tabs}
						style={{ marginBottom: 74 }}
						onChange={onChange}
						activeKey={activeKey}
					/>
					<DeleteExchangeConfig
						id={[integrationId]}
						disabled={!integrationId}
						fromConfigPage
					/>
				</>
			)}
		</Flex>
	);
};

export const IntegrationDetail = React.memo(IntegrationDetailUi);
