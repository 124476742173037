import { integrationsApi as api } from './newCreateApiFile';
export const addTagTypes = ['Connections', 'Configurations'] as const;
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			createConnectionMdmIntegrationsV1ConnectionsPost: build.mutation<
				CreateConnectionMdmIntegrationsV1ConnectionsPostApiResponse,
				CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections',
					method: 'POST',
					body: queryArg.connectionCreate,
				}),
				invalidatesTags: ['Connections'],
			}),
			listConnectionsMdmIntegrationsV1ConnectionsGet: build.query<
				ListConnectionsMdmIntegrationsV1ConnectionsGetApiResponse,
				ListConnectionsMdmIntegrationsV1ConnectionsGetApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections',
					params: {
						reverse: queryArg.reverse,
						sort_by: queryArg.sortBy,
						filter_by: queryArg.filterBy,
						page: queryArg.page,
						page_size: queryArg.pageSize,
					},
				}),
				providesTags: ['Connections'],
			}),
			getConnectionMdmIntegrationsV1ConnectionsConnectionIdGet: build.query<
				GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiResponse,
				GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
				}),
				providesTags: ['Connections'],
			}),
			updateConnectionMdmIntegrationsV1ConnectionsConnectionIdPut: build.mutation<
				UpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutApiResponse,
				UpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
					method: 'PUT',
					body: queryArg.connectionUpdateRequest,
				}),
				invalidatesTags: ['Connections'],
			}),
			deleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDelete: build.mutation<
				DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiResponse,
				DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Connections'],
			}),
			partialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatch:
				build.mutation<
					PartialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatchApiResponse,
					PartialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatchApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/connections/${queryArg.connectionId}`,
						method: 'PATCH',
						body: queryArg.connectionPartialUpdateRequest,
					}),
					invalidatesTags: ['Connections'],
				}),
			pinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatch: build.mutation<
				PinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatchApiResponse,
				PinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatchApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/pin`,
					method: 'PATCH',
					body: queryArg.pinConnectionRequest,
				}),
				invalidatesTags: ['Connections'],
			}),
			testConnectionMdmIntegrationsV1ConnectionsTestConnectionPost: build.mutation<
				TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiResponse,
				TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections/test-connection',
					method: 'POST',
					body: queryArg.connectionTest,
				}),
				invalidatesTags: ['Connections'],
			}),
			getSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGet: build.query<
				GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiResponse,
				GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiArg
			>({
				query: (queryArg) => ({
					url: '/mdm-integrations/v1/connections/systems/supported-systems',
					params: { page: queryArg.page, page_size: queryArg.pageSize },
				}),
				providesTags: ['Connections'],
			}),
			getConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGet:
				build.query<
					GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiResponse,
					GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/activation_mode`,
					}),
					providesTags: ['Configurations'],
				}),
			patchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatch:
				build.mutation<
					PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiResponse,
					PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
						method: 'PATCH',
						body: queryArg.exchangeConfigurationInput,
					}),
					invalidatesTags: ['Configurations'],
				}),
			getConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGet:
				build.query<
					GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiResponse,
					GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations/${queryArg.configurationId}`,
					}),
					providesTags: ['Configurations'],
				}),
			createConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPost:
				build.mutation<
					CreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiResponse,
					CreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
						method: 'POST',
						body: queryArg.configurationCreateRequest,
					}),
					invalidatesTags: ['Configurations'],
				}),
			getConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGet: build.query<
				GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiResponse,
				GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiArg
			>({
				query: (queryArg) => ({
					url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations`,
				}),
				providesTags: ['Configurations'],
			}),
			getConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGet:
				build.query<
					GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiResponse,
					GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiArg
				>({
					query: (queryArg) => ({
						url: `/mdm-integrations/v1/connections/${queryArg.connectionId}/configurations_status`,
					}),
					providesTags: ['Configurations'],
				}),
		}),
		overrideExisting: false,
	});
export { injectedRtkApi as integrationsApi };
export type CreateConnectionMdmIntegrationsV1ConnectionsPostApiResponse =
	/** status 200 Successful Response */ ConnectionCreateResponse;
export type CreateConnectionMdmIntegrationsV1ConnectionsPostApiArg = {
	connectionCreate: ConnectionCreate;
};
export type ListConnectionsMdmIntegrationsV1ConnectionsGetApiResponse =
	/** status 200 Successful Response */ ListConnectionsResponse;
export type ListConnectionsMdmIntegrationsV1ConnectionsGetApiArg = {
	reverse: boolean;
	sortBy?: string | null;
	filterBy?: string | null;
	page?: number;
	pageSize?: number;
};
export type GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiResponse =
	/** status 200 Successful Response */ GetConnectionResponse;
export type GetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetApiArg = {
	connectionId: string;
};
export type UpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutApiResponse =
	/** status 200 Connection updated successfully */ ConnectionUpdateResponse;
export type UpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutApiArg = {
	connectionId: string;
	connectionUpdateRequest: ConnectionUpdateRequest;
};
export type DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiResponse =
	/** status 204 No content */ string;
export type DeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteApiArg = {
	connectionId: string;
};
export type PartialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatchApiResponse =
	/** status 200 Connection updated successfully */ any;
export type PartialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatchApiArg =
	{
		connectionId: string;
		connectionPartialUpdateRequest: ConnectionPartialUpdateRequest;
	};
export type PinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatchApiResponse =
	/** status 200 Connection pinned successfully */ object;
export type PinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatchApiArg = {
	connectionId: string;
	pinConnectionRequest: PinConnectionRequest;
};
export type TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiResponse =
	/** status 200 Successful Response */ ConnectionTestResponse;
export type TestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostApiArg = {
	connectionTest: ConnectionTest;
};
export type GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiResponse =
	/** status 200 List of supported systems retrieved successfully */ SupportedSystemsResponse;
export type GetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetApiArg = {
	page?: number;
	pageSize?: number;
};
export type GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiResponse =
	/** status 200 Successful Response */ ActivationMode[];
export type GetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetApiArg =
	{
		connectionId: string;
	};
export type PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiResponse =
	/** status 200 Successful Response */ ExchangeConfiguration;
export type PatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchApiArg =
	{
		connectionId: string;
		configurationId: string;
		exchangeConfigurationInput: ExchangeConfiguration2;
	};
export type GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiResponse =
	/** status 200 Successful Response */ ConfigurationGetResponse;
export type GetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetApiArg =
	{
		connectionId: string;
		configurationId: string;
	};
export type CreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiResponse =
	/** status 200 Successful Response */ ConfigurationCreateResponse;
export type CreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostApiArg = {
	connectionId: string;
	configurationCreateRequest: ConfigurationCreateRequest;
};
export type GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiResponse =
	/** status 200 Successful Response */ ConfigurationList;
export type GetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetApiArg = {
	connectionId: string;
};
export type GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiResponse =
	/** status 200 Successful Response */ string[];
export type GetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetApiArg =
	{
		connectionId: string;
	};
export type ConnectionCreateResponse = {
	connection_id: string;
};
export type ValidationError = {
	loc: (string | number)[];
	msg: string;
	type: string;
};
export type HttpValidationError = {
	detail?: ValidationError[];
};
export type ConnectionCreate = {
	/** Field contains id of certain system type */
	system_id: string;
	/** Human-readable name for connection */
	connection_name: string;
	/** Contains params for certain connection */
	connection_params: object;
};
export type ConnectionInfo = {
	name: string;
	date_last_import?: string | null;
	count_configuration: number;
	count_successful_imports: number;
	count_failed_imports: number;
	is_pinned?: boolean;
	is_failed?: boolean;
	is_active?: boolean;
};
export type Integration = {
	connection_id: string;
	source_type: string;
	connection_info: ConnectionInfo;
};
export type PaginationInfo = {
	total: number;
	page_size: number;
	current_page: number;
};
export type ListConnectionsResponse = {
	data: Integration[];
	pagination: PaginationInfo;
};
export type ConnectionProperty = {
	return_parameter: string;
	title: string | null;
	type: string;
	mandatory: boolean;
	current_value: string | null;
};
export type GetConnectionResponse = {
	connection_properties: ConnectionProperty[];
	connection_info: ConnectionInfo;
};
export type ConnectionUpdateResponse = {
	detail: string;
};
export type ConnectionUpdateRequest = {
	conn_name: string;
	connection_params: object;
};
export type ConnectionPartialUpdateRequest = {
	conn_name?: string | null;
	connection_params?: object | null;
};
export type PinConnectionRequest = {
	is_pinned?: boolean;
};
export type ConnectionTestResponse = {
	status?: boolean;
	message?: string;
};
export type ConnectionTest = {
	connection_params: object;
};
export type ConnectionParameter = {
	return_parametr: string;
	title: string;
	type: string;
	mandatory: boolean;
	example: string;
};
export type SupportedSystem = {
	system_id: string;
	system_name: string;
	system_short_name: string;
	connection_parameters: ConnectionParameter[];
};
export type SupportedSystemsResponse = {
	data: SupportedSystem[];
	pagination: PaginationInfo;
};
export type ExecutionType = 'scheduled' | 'manual' | 'manual_immediate' | 'external_event';
export type ActivationMode = {
	activation_mode_id: string;
	execution_type: ExecutionType;
	schedule_interval: string | null;
	description: string | null;
};
export type ExchangeAction = {
	exchange_action_id: string;
	description: string;
	ticker: string;
};
export type Metadata = {
	metadata_id: string;
	connection_id: string;
	data: object;
};
export type ConfigurationWithImportChangeData = {
	id: string;
	name: string;
	exchange_action?: ExchangeAction | null;
	description?: string | null;
	catalog_id: string;
	connection_id: string;
	status: string;
	metadata_id: Metadata;
	created_at: string;
	updated_at: string;
	activation_mode: ActivationMode;
	on_delete_action: string;
	import_change_data?: boolean | null;
};
export type ExchangeConfiguration = {
	exchange_configuration: ConfigurationWithImportChangeData;
};
export type ConfigurationWithImportChangeData2 = {
	id: string;
	name: string;
	exchange_action?: ExchangeAction | null;
	description?: string | null;
	catalog_id: string;
	connection_id: string;
	status: string;
	metadata_id: Metadata;
	created_at: string;
	updated_at: string;
	activation_mode: ActivationMode;
	on_delete_action: string;
	import_change_data?: boolean | null;
};
export type ExchangeConfiguration2 = {
	exchange_configuration: ConfigurationWithImportChangeData2;
};
export type GetConfigurationSchedule = {
	weekday: string;
	import_frequency: number;
};
export type GetConfigurationMatchingField = {
	name_guid_column: string;
	name_master_column: string;
	flag_transaction_key?: boolean | null;
};
export type ExchangeConfigurationAllFieldsRequired = {
	id: string;
	name: string;
	exchange_action?: ExchangeAction | null;
	description: string;
	catalog_id: string;
	connection_id: string;
	status: string;
	metadata_id: Metadata;
	created_at: string;
	updated_at: string;
	activation_mode: ActivationMode;
	on_delete_action: string;
	id_action_transition_key: string;
	import_schedule: GetConfigurationSchedule;
	matching_fields: GetConfigurationMatchingField;
};
export type GetConfigurationConfigurationListObject = {
	configuration_id: string;
	exchange_configuration: ExchangeConfigurationAllFieldsRequired;
};
export type ConfigurationGetResponse = {
	configuration_list: GetConfigurationConfigurationListObject[];
};
export type ConfigurationCreateResponse = {
	configuration_id: string;
};
export type ConfigurationCreateRequest = {
	name: string;
	description?: string | null;
	on_delete_action: string;
	status: string;
	execution_type: ExecutionType;
	schedule_interval?: string | null;
	table_extraction_names: string[];
};
export type ConfigurationListObject = {
	configuration_id: string;
	configuration_name: string;
	date_last_import: string;
	date_next_import: string;
	status: string;
};
export type ConfigurationList = {
	configuration_list: ConfigurationListObject[];
};
export const {
	useCreateConnectionMdmIntegrationsV1ConnectionsPostMutation,
	useListConnectionsMdmIntegrationsV1ConnectionsGetQuery,
	useLazyListConnectionsMdmIntegrationsV1ConnectionsGetQuery,
	useGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery,
	useLazyGetConnectionMdmIntegrationsV1ConnectionsConnectionIdGetQuery,
	useUpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutMutation,
	useDeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteMutation,
	usePartialUpdateConnectionMdmIntegrationsV1ConnectionsConnectionsConnectionIdPatchMutation,
	usePinConnectionMdmIntegrationsV1ConnectionsConnectionIdPinPatchMutation,
	useTestConnectionMdmIntegrationsV1ConnectionsTestConnectionPostMutation,
	useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
	useLazyGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
	useGetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetQuery,
	useLazyGetConfigurationsActivationModeMdmIntegrationsV1ConnectionsConnectionIdConfigurationsActivationModeGetQuery,
	usePatchConnectionMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchMutation,
	useGetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery,
	useLazyGetConnectionByIdMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery,
	useCreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostMutation,
	useGetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery,
	useLazyGetConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery,
	useGetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetQuery,
	useLazyGetConfigurationsStatusMdmIntegrationsV1ConnectionsConnectionIdConfigurationsStatusGetQuery,
} = injectedRtkApi;
