import { RiDeleteBinLine } from '@remixicon/react';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { routes } from 'shared/config';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';

interface IDeleteExchangeConfigProps {
	id: Array<string>;
	isButton?: boolean;
	disabled?: boolean;
	fromConfigPage?: boolean;
}

const DeleteExchangeConfigUi: FC<IDeleteExchangeConfigProps> = ({
	id,
	isButton,
	disabled,
	fromConfigPage,
}) => {
	const navigate = useNavigate();
	const { integrationId } = useParams();
	const handleDelete = () =>
		new Promise((resolve) => {
			resolve(id);
		}).then((res) => {
			console.log(res);
			if (fromConfigPage)
				navigate(
					`/${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`
				);
		});

	return (
		<ButtonVsDropdownLink
			title="Удалить"
			callback={handleDelete}
			icon={<RiDeleteBinLine />}
			type={!isButton ? 'dropdownLink' : null}
			isDisabled={disabled}
		/>
	);
};

export const DeleteExchangeConfig = React.memo(DeleteExchangeConfigUi);
